import { v4 as uuidV4 } from 'uuid';
import { clearStorage, createStorage } from '@/libs/proxy/storage.proxy';
import { Injector } from '@/libs/injector/injector';

export class RouterParams {
  caches = createStorage('router-params', {});

  set(params) {
    const id = uuidV4();
    this.caches[id] = params;
    return id;
  }

  get(id) {
    return this.caches[id];
  }

  remove(id) {
    this.caches[id] = null;
  }

  clear(includes, excludes) {
    clearStorage(this.caches, { excludes });
  }
}

export const $routerParams = Injector(RouterParams)
  .get();
