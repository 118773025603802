import {Subject} from 'rxjs';
import Storage from '@/utils/storage';
import {$project} from '@/libs/project/project';

export class LayoutNavBarUser {
  nickname = '';
  avatar = '';
  onClick = new Subject();

  constructor() {
    this.load();
  }

  load() {
    const user = Storage.getItem('admin_user');
    if (user) {
      this.nickname = user.username;
      this.avatar = user.face;
    }
  }
}

export class LayoutNavBarService {
  appName = '百泽纳超级管理系统';
  appNameEn = 'Super management system';
  logo = '';
  user = new LayoutNavBarUser();
  profileActions = [];
  leftActions = [];
  rightActions = [];
  onLogoClick = new Subject();

  constructor() {
    // this.profileActions.push({
    //   title: '修改密码',
    // });

    // this.profileActions.push({
    //   title: '退出登录',
    //   handler: () => {
    //     const app = $project.getApp();
    //     app.$store.dispatch('logoutAction').then(() => {
    //       app.$router.replace({name: '登录'});
    //     });
    //   },
    // });

    this.rightActions.push({
      icon: 'el-icon-switch-button',
      // title: '退出登录',
      badge: 0,
      handler: () => {
        const app = $project.getApp();
        app.$store.dispatch('logoutAction').then(() => {
          app.$router.replace({name: '登录'});
        });
      },
    });
  }
}
