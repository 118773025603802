import { createRouterProxy } from '@/libs/router/router';
// start_import
import Layout from '@/libs/layout/layout.vue'
import RouterOutlet from '@/libs/layout/router-outlet/router-outlet.vue'
// end_import

// start_static_routes
const routes = [
  {
    name: '根页面',
    path: '',
    component: Layout,
    children: [
      {
        name: '首页',
        path: '/home',
        meta: {
          name: '首页',
          icon: 'el-icon-house',
          isAuthRequired: true,
          isFirst: true
        },
        component: () => import('./home/home.vue')
      }
    ],
    redirect: '/home'
  },
  {
    name: '状态模块',
    path: '/status',
    meta: {
      name: '状态模块',
      isStatic: true,
      isVirtual: true
    },
    component: RouterOutlet,
    children: [
      {
        name: '错误页404',
        path: 'error-404',
        meta: {
          name: '错误页404',
          isDisableCached: true
        },
        component: () => import('./$status/error-404/error-404.vue')
      },
      {
        name: '错误页401',
        path: 'error-401',
        meta: {
          name: '错误页401',
          isDisableCached: true
        },
        component: () => import('./$status/error-401/error-401.vue')
      }
    ]
  },
  {
    name: '授权模块',
    path: '/auth',
    meta: {
      name: '授权模块',
      isStatic: true,
      isVirtual: true
    },
    component: RouterOutlet,
    children: [
      {
        name: '登录',
        path: 'login',
        meta: {
          name: '登录',
          isDisableCached: true
        },
        component: () => import('./$auth/login/login.vue')
      },
      {
        name: '修改密码',
        path: 'reset-password',
        meta: {
          name: '修改密码',
          isDisableCached: true
        },
        component: () => import('./$auth/reset-password/reset-password.vue')
      }
    ]
  }
];
// end_static_routes

// start_dynamic_routes
const dynamicRoutes = [
  {
    name: '活动管理',
    path: '/activity',
    meta: {
      name: '活动管理',
      icon: 'el-icon-magic-stick',
      isAuthRequired: true
    },
    component: Layout,
    children: [
      {
        name: '活动列表',
        path: 'activity-list',
        meta: {
          name: '活动列表',
          isFirst: true
        },
        component: () => import('./activity/activity-list/activity-list.vue')
      },
      {
        name: '活动详情',
        path: 'activity-detail/:id',
        meta: {
          name: '活动详情',
          isVirtual: true,
          bornIn: '活动列表',
          queryKeys: [
            'id'
          ]
        },
        component: () => import('./activity/activity-detail/activity-detail.vue')
      },
      {
        name: '活动审核',
        path: 'activity-review',
        meta: {
          name: '活动审核'
        },
        component: () => import('./activity/activity-review/activity-review.vue')
      },
      {
        name: '活动配置',
        path: 'activity-config',
        meta: {
          name: '活动配置'
        },
        component: RouterOutlet,
        children: [
          {
            name: '活动分类',
            path: 'activity-category-list',
            meta: {
              name: '活动分类'
            },
            component: () => import('./activity/activity-config/activity-category-list/activity-category-list.vue')
          },
          {
            name: '标签管理',
            path: 'activity-tag-list',
            meta: {
              name: '标签管理'
            },
            component: () => import('./activity/activity-config/activity-tag-list/activity-tag-list.vue')
          }
        ]
      },
      {
        name: '活动操作日志',
        path: 'activity-ops-log',
        meta: {
          name: '活动操作日志'
        },
        component: () => import('./activity/activity-ops-log/activity-ops-log.vue')
      }
    ],
    redirect: '/activity/activity-list'
  },
  {
    name: '订单管理',
    path: '/order',
    meta: {
      name: '订单管理',
      icon: 'el-icon-shopping-cart-1',
      isAuthRequired: true
    },
    component: Layout,
    children: [
      {
        name: '订单列表',
        path: 'order-list',
        meta: {
          name: '订单列表',
          isFirst: true
        },
        component: () => import('./order/order-list/order-list.vue')
      },
      {
        name: '核销记录',
        path: 'verification-list',
        meta: {
          name: '核销记录',
          isFirst: true
        },
        component: () => import('./order/verification-list/verification-list.vue')
      },
      {
        name: '订单详情',
        path: 'order-detail/:order_id',
        meta: {
          name: '订单详情',
          isVirtual: true,
          bornIn: '订单列表',
          queryKeys: [
            'order_id'
          ]
        },
        component: () => import('./order/order-detail/order-detail.vue')
      },

      {
        name: '活动参与人详情',
        path: 'activity-participant-detail/:order_id',
        meta: {
          name: '活动参与人详情',
          isVirtual: true,
          bornIn: '订单列表',
          queryKeys: [
            'order_id'
          ]
        },
        component: () => import('./order/activity-participant-detail/activity-participant-detail.vue')
      }
    ],
    redirect: '/order/order-list'
  },
  {
    name: '商户管理',
    path: '/merchant',
    meta: {
      name: '商户管理',
      icon: 'el-icon-suitcase-1',
      isAuthRequired: true
    },
    component: Layout,
    children: [
      {
        name: '商户列表',
        path: 'merchant-list',
        meta: {
          name: '商户列表',
          isFirst: true
        },
        component: () => import('./merchant/merchant-list/merchant-list.vue')
      },
      {
        name: '商户详情',
        path: 'merchant-detail/:id',
        meta: {
          name: '商户详情',
          isVirtual: true,
          bornIn: '商户列表',
          queryKeys: [
            'id'
          ]
        },
        component: () => import('./merchant/merchant-detail/merchant-detail.vue')
      },
      {
        name: '商户用户列表',
        path: 'merchant-user-list',
        meta: {
          name: '商户用户列表'
        },
        component: () => import('./merchant/merchant-user-list/merchant-user-list.vue')
      },
      {
        name: '商户申请列表',
        path: 'merchant-apply-list',
        meta: {
          name: '商户申请列表'
        },
        component: () => import('./merchant/merchant-apply-list/merchant-apply-list.vue')
      },
      {
        name: '商户申请详情',
        path: 'merchant-apply-detail/:id',
        meta: {
          name: '商户申请详情',
          isVirtual: true,
          bornIn: '商户申请列表',
          queryKeys: [
            'id'
          ]
        },
        component: () => import('./merchant/merchant-apply-detail/merchant-apply-detail.vue')
      },
      {
        name: '商户操作日志',
        path: 'merchant-ops-log',
        meta: {
          name: '商户操作日志'
        },
        component: () => import('./merchant/merchant-ops-log/merchant-ops-log.vue')
      },
      {
        name: '商户退款活动top5',
        path: 'merchant-refund-activity-top5',
        meta: {
          name: '商户退款活动top5',
          isVirtual: true,
          bornIn: '首页'
        },
        component: () => import('./merchant/merchant-refund-activity-top5/merchant-refund-activity-top5.vue')
      }
    ],
    redirect: '/merchant/merchant-list'
  },
  {
    name: '客户管理',
    path: '/customer',
    meta: {
      name: '客户管理',
      icon: 'el-icon-user',
      isAuthRequired: true
    },
    component: Layout,
    children: [
      {
        name: '客户列表',
        path: 'customer-list',
        meta: {
          name: '客户列表',
          isFirst: true
        },
        component: () => import('./customer/customer-list/customer-list.vue')
      },
      {
        name: '客户详情',
        path: 'customer-detail/:id',
        meta: {
          name: '客户详情',
          isVirtual: true,
          bornIn: '客户列表',
          queryKeys: [
            'id'
          ]
        },
        component: () => import('./customer/customer-detail/customer-detail.vue')
      },
      {
        name: '客户黑名单',
        path: 'customer-black-list',
        meta: {
          name: '客户黑名单'
        },
        component: () => import('./customer/customer-black-list/customer-black-list.vue')
      },
      {
        name: '分销管理',
        path: 'distribution',
        meta: {
          name: '分销管理'
        },
        component: RouterOutlet,
        children: [
          {
            name: '分销商列表',
            path: 'distributor-list',
            meta: {
              name: '分销商列表',
              // isVirtual: true,
              bornIn: '分销管理'
            },
            component: () => import('./customer/distribution/distributor-list/distributor-list.vue')
          },
          {
            name: '分销商订单列表',
            path: 'distribution-order-list/',
            meta: {
              name: '分销商订单列表',
              isVirtual: true,
              bornIn: '分销商列表',
            },
            component: () => import('./customer/distribution/distribution-order-list/distribution-order-list')
          },
          {
            name: '分销商详情',
            path: 'distributor-detail/:id',
            meta: {
              name: '分销商详情',
              isVirtual: true,
              bornIn: '分销商列表',
              queryKeys: [
                'id'
              ]
            },
            component: () => import('./customer/distribution/distributor-detail/distributor-detail.vue')
          },
          {
            name: '代理等级管理',
            path: 'agent-level-manager',
            meta: {
              name: '代理等级管理'
            },
            component: () => import('./customer/distribution/agent-level-manager/agent-level-manager.vue')
          },
          {
            name: '分销活动管理',
            path: 'distribution-activity-manager',
            meta: {
              name: '分销活动管理'
            },
            component: () => import('./customer/distribution/distribution-activity-manager/distribution-activity-manager.vue')
          }
        ]
      },
      {
        name: '问题反馈',
        path: 'feedback-list',
        meta: {
          name: '问题反馈'
        },
        component: () => import('./customer/feedback-list/feedback-list.vue')
      }
    ],
    redirect: '/customer/customer-list'
  },
  {
    name: '账户管理',
    path: '/account',
    meta: {
      name: '账户管理',
      icon: 'el-icon-wallet',
      isAuthRequired: true
    },
    component: Layout,
    children: [
      {
        name: '商户资产概况',
        path: 'merchant-capital-profile',
        meta: {
          name: '商户资产概况',
          isFirst: true
        },
        component: () => import('./account/merchant-capital-profile/merchant-capital-profile.vue')
      },
      {
        name: '结算明细',
        path: 'transaction-list/:id',
        meta: {
          name: '结算明细',
          isVirtual: true,
          bornIn: '商户资产概况',
          queryKeys: [
            'id'
          ]
        },
        component: () => import('./account/transaction-list/transaction-list.vue')
      },
      {
        name: '交易流水详情',
        path: 'transaction-detail/:id',
        meta: {
          name: '交易流水详情',
          isVirtual: true,
          bornIn: '商户资产概况',
          queryKeys: [
            'id'
          ]
        },
        component: () => import('./account/transaction-detail/transaction-detail.vue')
      },
      {
        name: '交易退款明细',
        path: 'transaction-refund-list/:id',
        meta: {
          name: '交易退款明细',
          isVirtual: true,
          bornIn: '商户资产概况',
          queryKeys: [
            'id'
          ]
        },
        component: () => import('./account/transaction-refund-list/transaction-refund-list.vue')
      },
      {
        name: '平台营收',
        path: 'platform-revenue',
        meta: {
          name: '平台营收'
        },
        component: () => import('./account/platform-revenue/platform-revenue.vue')
      },
      {
        name: '平台对账',
        path: 'platform-bill',
        meta: {
          name: '平台对账'
        },
        component: () => import('./account/platform-bill/platform-bill.vue')
      },
      {
        name: '商户提现记录',
        path: 'merchant-withdrawal-list',
        meta: {
          name: '商户提现记录'
        },
        component: () => import('./account/merchant-withdrawal-list/merchant-withdrawal-list.vue')
      },
      {
        name: '代理人提现记录',
        path: 'agent-withdrawal-list',
        meta: {
          name: '代理人提现记录'
        },
        component: () => import('./account/agent-withdrawal-list/agent-withdrawal-list.vue')
      }
    ],
    redirect: '/account/merchant-capital-profile'
  },
  {
    name: '运营管理',
    path: '/ops',
    meta: {
      name: '运营管理',
      icon: 'el-icon-data-line',
      isAuthRequired: true
    },
    component: Layout,
    children: [
      {
        name: '统计分析',
        path: 'statistics',
        meta: {
          name: '统计分析',
          isFirst: true
        },
        component: RouterOutlet,
        children: [
          {
            name: '交易分析',
            path: 'transaction-statistics',
            meta: {
              name: '交易分析',
              isFirst: true
            },
            component: () => import('./ops/statistics/transaction-statistics/transaction-statistics.vue')
          },
          {
            name: '活动分析',
            path: 'activity-statistics',
            meta: {
              name: '活动分析'
            },
            component: () => import('./ops/statistics/activity-statistics/activity-statistics.vue')
          },
          {
            name: '访客来源分析',
            path: 'visitor-source-statistics',
            meta: {
              name: '访客来源分析'
            },
            component: () => import('./ops/statistics/visitor-source-statistics/visitor-source-statistics.vue')
          },
          {
            name: '关键词统计',
            path: 'keywords-statistics',
            meta: {
              name: '关键词统计'
            },
            component: () => import('./ops/statistics/keywords-statistics/keywords-statistics.vue')
          }
        ],
        redirect: '/ops/statistics/transaction-statistics'
      },
      {
        name: '广告位分析',
        path: 'ad',
        meta: {
          name: '广告位分析'
        },
        component: RouterOutlet,
        children: [
          {
            name: '广告位转化统计',
            path: 'ad-conversion-statistics',
            meta: {
              name: '广告位转化统计'
            },
            component: () => import('./ops/ad/ad-conversion-statistics/ad-conversion-statistics.vue')
          }
        ]
      },
      {
        name: '分销运营统计',
        path: 'distribution',
        meta: {
          name: '分销运营统计'
        },
        component: RouterOutlet,
        children: [
          {
            name: '分销活动排行榜',
            path: 'distribution-activities-rank-list',
            meta: {
              name: '分销活动排行榜'
            },
            component: () => import('./ops/distribution/distribution-activities-rank-list/distribution-activities-rank-list.vue')
          },
          {
            name: '分销客户来源分析',
            path: 'distribution-customers-source-analysis',
            meta: {
              name: '分销客户来源分析'
            },
            component: () => import('./ops/distribution/distribution-customers-source-analysis/distribution-customers-source-analysis.vue')
          },
          {
            name: '分销活动转化率',
            path: 'distribution-activities-conversion-statistics',
            meta: {
              name: '分销活动转化率'
            },
            component: () => import('./ops/distribution/distribution-activities-conversion-statistics/distribution-activities-conversion-statistics.vue')
          }
        ]
      },
      {
        name: '客户画像',
        path: 'customer',
        meta: {
          name: '客户画像'
        },
        component: RouterOutlet,
        children: [
          {
            name: '客户类型及分布',
            path: 'customer-type-and-distribution',
            meta: {
              name: '客户类型及分布'
            },
            component: () => import('./ops/customer/customer-type-and-distribution/customer-type-and-distribution.vue')
          }
        ]
      },
      {
        name: '商户运营情况',
        path: 'ops-status',
        meta: {
          name: '商户运营情况'
        },
        component: RouterOutlet,
        children: [
          {
            name: '订单和访问量统计',
            path: 'orders-and-visits-statistics',
            meta: {
              name: '订单和访问量统计'
            },
            component: () => import('./ops/ops-status/orders-and-visits-statistics/orders-and-visits-statistics.vue')
          },
          {
            name: '客单价及退款情况',
            path: 'unit-price-and-refund',
            meta: {
              name: '客单价及退款情况'
            },
            component: () => import('./ops/ops-status/unit-price-and-refund/unit-price-and-refund.vue')
          }
        ]
      }
    ],
    redirect: '/ops/statistics'
  },
  {
    name: '平台管理',
    path: '/platform',
    meta: {
      name: '平台管理',
      icon: 'el-icon-news',
      isAuthRequired: true
    },
    component: Layout,
    children: [
      {
        name: '短信管理',
        path: 'sms',
        meta: {
          name: '短信管理',
          isFirst: true
        },
        component: RouterOutlet,
        children: [
          {
            name: '消息列表',
            path: 'sms-template-list',
            meta: {
              name: '消息列表',
              isFirst: true
            },
            component: () => import('./platform/sms/sms-template-list/sms-template-list.vue')
          },
          {
            name: '消息详情',
            path: 'sms-template-detail/:id',
            meta: {
              name: '消息详情',
              isVirtual: true,
              bornIn: '消息列表',
              queryKeys: [
                'id'
              ]
            },
            component: () => import('./platform/sms/sms-template-detail/sms-template-detail.vue')
          },
          {
            name: '主动消息模板管理',
            path: 'activesms-template-list',
            meta: {
              name: '主动消息模板管理'
            },
            component: () => import('./platform/sms/activesms-template-list/activesms-template-list.vue')
          },
          {
            name: '短信发送记录',
            path: 'sms-send-list',
            meta: {
              name: '短信发送记录'
            },
            component: () => import('./platform/sms/sms-send-list/sms-send-list.vue')
          }
        ],
        redirect: '/platform/sms/sms-template-list'
      },
      {
        name: '平台规范管理',
        path: 'spec',
        meta: {
          name: '平台规范管理'
        },
        component: RouterOutlet,
        children: [
          {
            name: '商户规范列表',
            path: 'spec-merchant-list',
            meta: {
              name: '商户规范列表'
            },
            component: () => import('./platform/spec/spec-merchant-list/spec-merchant-list.vue')
          },
          {
            name: '商户规范编辑',
            path: 'spec-merchant-editor',
            meta: {
              name: '商户规范编辑',
              isVirtual: true
            },
            component: () => import('./platform/spec/spec-merchant-editor/spec-merchant-editor.vue')
          },
          {
            name: '平台规范列表',
            path: 'spec-platform-list',
            meta: {
              name: '平台规范列表'
            },
            component: () => import('./platform/spec/spec-platform-list/spec-platform-list.vue')
          },
          {
            name: '平台规范编辑',
            path: 'spec-platform-editor',
            meta: {
              name: '平台规范编辑',
              isVirtual: true
            },
            component: () => import('./platform/spec/spec-platform-editor/spec-platform-editor.vue')
          },
          {
            name: '代理规范列表',
            path: 'spec-agent-list',
            meta: {
              name: '代理规范列表'
            },
            component: () => import('./platform/spec/spec-agent-list/spec-agent-list.vue')
          },
          {
            name: '代理规范编辑',
            path: 'spec-agent-editor',
            meta: {
              name: '代理规范编辑',
              isVirtual: true
            },
            component: () => import('./platform/spec/spec-agent-editor/spec-agent-editor.vue')
          }
        ]
      },
      {
        name: '平台装修',
        path: 'renovation',
        meta: {
          name: '平台装修'
        },
        component: RouterOutlet,
        children: [
          {
            name: '参数设置',
            path: 'params-manager',
            meta: {
              name: '参数设置'
            },
            component: () => import('./platform/renovation/params-manager/params-manager.vue')
          },
          {
            name: '首页焦点图设置',
            path: 'home-banner-manager',
            meta: {
              name: '首页焦点图设置'
            },
            component: () => import('./platform/renovation/home-banner-manager/home-banner-manager.vue')
          },
          {
            name: '专区管理',
            path: 'activity-zone-list',
            meta: {
              name: '专区管理'
            },
            component: () => import('./platform/renovation/activity-zone-list/activity-zone-list.vue')
          },
          {
            name: '专区详情',
            path: 'activity-zone-detail/:id',
            meta: {
              name: '专区详情',
              isVirtual: true,
              bornIn: '专区管理',
              queryKeys: [
                'id'
              ]
            },
            component: () => import('./platform/renovation/activity-zone-detail/activity-zone-detail.vue')
          },
          {
            name: '资讯详情',
            path: 'activity-information-detail/:id?',
            meta: {
              name: '资讯详情',
              isVirtual: true,
              bornIn: '专区管理',
              queryKeys: [
                'id'
              ]
            },
            component: () => import('./platform/renovation/activity-information-detail/activity-information-detail.vue')
          },
          {
            name: '上新专区',
            path: 'latest-zone',
            meta: {
              name: '上新专区'
            },
            component: () => import('./platform/renovation/latest-zone/latest-zone.vue')
          },
          {
            name: '热门关键字',
            path: 'hot-keywords',
            meta: {
              name: '热门关键字'
            },
            component: () => import('./platform/renovation/hot-keywords/hot-keywords.vue')
          }
        ]
      },
      {
        name: '权限管理',
        path: 'power',
        meta: {
          name: '权限管理'
        },
        component: RouterOutlet,
        children: [
          {
            name: '用户管理',
            path: 'user-list',
            meta: {
              name: '用户管理'
            },
            component: () => import('./platform/power/user-list/user-list.vue')
          },
          {
            name: '用户详情',
            path: 'user-detail/:id',
            meta: {
              name: '用户详情',
              isVirtual: true,
              bornIn: '用户管理',
              queryKeys: [
                'id'
              ]
            },
            component: () => import('./platform/power/user-detail/user-detail.vue')
          },
          {
            name: '角色管理',
            path: 'role-list',
            meta: {
              name: '角色管理'
            },
            component: () => import('./platform/power/role-list/role-list.vue')
          },
          {
            name: '角色详情',
            path: 'role-detail/:id',
            meta: {
              name: '角色详情',
              isVirtual: true,
              bornIn: '角色列表',
              queryKeys: [
                'id'
              ]
            },
            component: () => import('./platform/power/role-detail/role-detail.vue')
          }
        ]
      },
      {
        name: '平台操作日志',
        path: 'platform-ops-log',
        meta: {
          name: '平台操作日志'
        },
        component: () => import('./platform/platform-ops-log/platform-ops-log.vue')
      },
      {
        name: '平台设置',
        path: 'platform-settings',
        meta: {
          name: '平台设置'
        },
        component: () => import('./platform/platform-settings/platform-settings.vue')
      },
      {
        name: '地址设置',
        path: 'address-settings',
        meta: {
          name: '地址设置'
        },
        component: () => import('./platform/address-settings/address-settings.vue')
      }
    ],
    redirect: '/platform/sms'
  }
];
// end_dynamic_routes

// start_routes
const routesMap = {
  ['首页']: '/home',
  ['错误页404']: '/status/error-404',
  ['错误页401']: '/status/error-401',
  ['登录']: '/auth/login',
  ['修改密码']: '/auth/reset-password',
  ['活动列表']: '/activity/activity-list',
  ['活动详情']: '/activity/activity-detail/:id',
  ['活动审核']: '/activity/activity-review',
  ['活动分类']: '/activity/activity-config/activity-category-list',
  ['标签管理']: '/activity/activity-config/activity-tag-list',
  ['活动操作日志']: '/activity/activity-ops-log',
  ['订单列表']: '/order/order-list',
  ['订单详情']: '/order/order-detail/:id',
  ['活动参与人详情']: '/order/activity-participant-detail/:id',
  ['商户列表']: '/merchant/merchant-list',
  ['商户详情']: '/merchant/merchant-detail/:id',
  ['商户用户列表']: '/merchant/merchant-user-list',
  ['商户申请列表']: '/merchant/merchant-apply-list',
  ['商户申请详情']: '/merchant/merchant-apply-detail/:id',
  ['商户操作日志']: '/merchant/merchant-ops-log',
  ['商户退款活动top5']: '/merchant/merchant-refund-activity-top5',
  ['客户列表']: '/customer/customer-list',
  ['客户详情']: '/customer/customer-detail/:id',
  ['客户黑名单']: '/customer/customer-black-list',
  ['分销商列表']: '/customer/distribution/distributor-list',
  ['分销商详情']: '/customer/distribution/distributor-detail/:id',
  ['代理等级管理']: '/customer/distribution/agent-level-manager',
  ['分销活动管理']: '/customer/distribution/distribution-activity-manager',
  ['问题反馈']: '/customer/feedback-list',
  ['商户资产概况']: '/account/merchant-capital-profile',
  ['结算明细']: '/account/transaction-list/:id',
  ['交易流水详情']: '/account/transaction-detail/:id',
  ['交易退款明细']: '/account/transaction-refund-list/:id',
  ['平台营收']: '/account/platform-revenue',
  ['平台对账']: '/account/platform-bill',
  ['商户提现记录']: '/account/merchant-withdrawal-list',
  ['代理人提现记录']: '/account/agent-withdrawal-list',
  ['交易分析']: '/ops/statistics/transaction-statistics',
  ['活动分析']: '/ops/statistics/activity-statistics',
  ['访客来源分析']: '/ops/statistics/visitor-source-statistics',
  ['关键词统计']: '/ops/statistics/keywords-statistics',
  ['广告位转化统计']: '/ops/ad/ad-conversion-statistics',
  ['分销活动排行榜']: '/ops/distribution/distribution-activities-rank-list',
  ['分销客户来源分析']: '/ops/distribution/distribution-customers-source-analysis',
  ['分销活动转化率']: '/ops/distribution/distribution-activities-conversion-statistics',
  ['客户类型及分布']: '/ops/customer/customer-type-and-distribution',
  ['订单和访问量统计']: '/ops/ops-status/orders-and-visits-statistics',
  ['客单价及退款情况']: '/ops/ops-status/unit-price-and-refund',
  ['消息列表']: '/platform/sms/sms-template-list',
  ['消息详情']: '/platform/sms/sms-template-detail/:id',
  ['主动消息模板管理']: '/platform/sms/activesms-template-list',
  ['短信发送记录']: '/platform/sms/sms-send-list',
  ['商户规范列表']: '/platform/spec/spec-merchant-list',
  ['商户规范编辑']: '/platform/spec/spec-merchant-editor',
  ['平台规范列表']: '/platform/spec/spec-platform-list',
  ['平台规范编辑']: '/platform/spec/spec-platform-editor',
  ['代理规范列表']: '/platform/spec/spec-agent-list',
  ['代理规范编辑']: '/platform/spec/spec-agent-editor',
  ['参数设置']: '/platform/renovation/params-manager',
  ['首页焦点图设置']: '/platform/renovation/home-banner-manager',
  ['专区管理']: '/platform/renovation/activity-zone-list',
  ['专区详情']: '/platform/renovation/activity-zone-detail/:id',
  ['上新专区']: '/platform/renovation/latest-zone',
  ['热门关键字']: '/platform/renovation/hot-keywords',
  ['用户管理']: '/platform/power/user-list',
  ['用户详情']: '/platform/power/user-detail/:id',
  ['角色管理']: '/platform/power/role-list',
  ['角色详情']: '/platform/power/role-detail/:id',
  ['平台操作日志']: '/platform/platform-ops-log',
  ['平台设置']: '/platform/platform-settings',
  ['地址设置']: '/platform/address-settings'
};
// end_routes

console.log('routesMap', routesMap, routes, dynamicRoutes);

export const $routerAdmin = createRouterProxy({
  mode: 'hash',
  routes,
  routesMap,
  dynamicRoutes,
});
