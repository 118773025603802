export default {
  setItem: (key, value) => {
    try {
      value = JSON.stringify(value);
    } catch (e) {
    }
    localStorage.setItem(key, value);
  },
  getItem: (key) => {
    let value = localStorage.getItem(key);
    try {
      value = JSON.parse(value);
    } catch (e) {
    }
    return value;
  },
  removeItem: (key) => {
    localStorage.removeItem(key);
  },
  clear: () => {
    localStorage.clear();
  }
}
