<template>
  <div class="side-bar disable-select">
    <el-menu
      ref="rootMenu"
      :default-active="$route.name"
      class="root-side-menu"
      :style="{width: ctrl.sidebar.isCollapse ? '' : ctrl.sidebar.width + 'px'}"
      :collapse="ctrl.sidebar.isCollapse"
      :background-color="ctrl.sidebar.backgroundColor"
      :text-color="ctrl.sidebar.textColor"
      :active-text-color="ctrl.sidebar.activeTextColor"
    >
      <menu-item v-if="!!ctrl.sidebar.submenus" :submenus="ctrl.sidebar.submenus"></menu-item>
    </el-menu>
  </div>
</template>

<script lang="js">
import {Component, Vue} from 'vue-property-decorator';
import MenuItem from './menu-item.vue';
import {$layout} from '../layout.service';

export default @Component({
  components: {
    MenuItem,
  },
})
class SideBar extends Vue {
  ctrl = $layout;

  mounted() {
  }
}
</script>

<style lang="scss">
$--color-primary: teal;
$--color-background: #F4F5F8;
$--color-text-regular: #606266;
$sidemenu-height: 56px;
$sidemenu-radius: 56px / 2;

.side-bar {
  min-height: calc(100vh - 60px);
  overflow: hidden;
  border-bottom-right-radius: $sidemenu-radius;
  border-top-right-radius: $sidemenu-radius;

  a {
    display: block;
  }

  .root-side-menu {
    min-height: calc(100vh - 60px);
    overflow: hidden;
    transition: all 0.3s;
    border-bottom-right-radius: $sidemenu-radius;
    border-top-right-radius: $sidemenu-radius;
    padding: $sidemenu-height 0;
    border: none;
    color: $--color-text-regular !important;

    &.el-menu--collapse {
      .el-submenu__title {
        span {
          opacity: 0;
        }

        .el-submenu__icon-arrow {
          opacity: 0;
        }
      }
    }
  }

  .el-menu {
    &.el-menu--inline {
      border-bottom-left-radius: $sidemenu-radius;
      border-top-left-radius: $sidemenu-radius;
      overflow: hidden;
    }
  }

  .el-submenu {
    border-bottom-left-radius: $sidemenu-radius;
    border-top-left-radius: $sidemenu-radius;
    overflow: hidden;
    position: relative;

    .el-submenu__title {
      border-bottom-left-radius: $sidemenu-radius;
      border-top-left-radius: $sidemenu-radius;
      transition: none !important;
      overflow: hidden;
      background-color: $--color-primary !important;

      &:hover {
        text-shadow: 0 0 8px rgba(255, 255, 255, 0.6);
      }

      * {
        color: inherit;
      }
    }

    &.is-active {
      background-color: $--color-background;

      * {
        background-color: inherit !important;
        color: inherit !important;
      }

      .el-submenu__title {
        color: $--color-primary !important;

        &:hover {
          text-shadow: 0 0 8px rgba(red($--color-primary), green($--color-primary), blue($--color-primary), 0.6);
        }
      }

      .el-menu-item {
        &:hover {
          text-shadow: 0 0 8px rgba(red($--color-primary), green($--color-primary), blue($--color-primary), 0.6);
        }
      }
    }
  }

  .el-menu-item {
    border-bottom-left-radius: $sidemenu-radius;
    border-top-left-radius: $sidemenu-radius;
    overflow: hidden;
    transition: none !important;
    background-color: $--color-primary !important;

    &:hover {
      text-shadow: 0 0 8px rgba(255, 255, 255, 0.6);
    }

    &.is-active {
      background-color: #F4F5F8 !important;
      color: $--color-primary !important;
      font-weight: bold;
    }

    i {
      color: inherit !important;
    }
  }
}
</style>
