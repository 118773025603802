import Vue from 'vue';
import VueRouter from 'vue-router';
import {Injector} from '@/libs/injector/injector';
import {$routerParams} from './router.params';

Vue.use(VueRouter);

const defaultGuard = (to, from, next) => next();

export class RouterProxy {
  guard;
  config;

  get ctx() {
    return this.config.router;
  }

  get routes() {
    return this.config.routes;
  }

  get dynamicRoutes() {
    return this.config.dynamicRoutes;
  }

  get routesMap() {
    return this.config.routesMap;
  }

  constructor(config) {
    this.config = config;
    this.ctx.beforeEach((to, from, next) => {
      (this.guard || defaultGuard)(to, from, next);
    });
  }

  setGuard(guard) {
    this.guard = guard;
  }

  fragment(fragment) {
    const route = this.ctx.currentRoute;
    return this.ctx.push({
      path: route.fullPath,
      query: route.query,
      hash: fragment,
      replace: true,
    });
  }

  forward(route, query, replace = false) {
    const location = {
      query,
      replace,
    };

    if (route.startsWith('/')) {
      location.path = route;
    } else if (this.config.routesMap && this.config.routesMap[route]) {
      let path = this.config.routesMap[route];
      if (query) {
        Object.keys(query).forEach(key => {
          const _path = path.replace(new RegExp(`:${key}`), query[key]);
          if (_path !== path) {
            path = _path;
            delete query[key];
          }
        });
      }
      location.path = path;
    } else {
      location.path = route;
    }

    return this.ctx.push(location);
  }

  /**
   * 该方法主要用于传递大对象时使用
   * 比如传递一个无法序列化的json对象，或者传递一个序列化后巨长的json对象
   * 如果直接转化成 query string，很有可能会出现url巨长的问题
   */
  forwardWithBigParams(route, params, replace = false) {
    const id = $routerParams.set(params);
    return this.forward(route, {id}, replace);
  }

  redirect(route, query) {
    return this.forward(route, query, true);
  }

  redirectWithBigParams(route, query) {
    return this.forwardWithBigParams(route, query, true);
  }

  backward() {
    this.ctx.back();
  }
}

export function createRouterProxy(options) {
  return Injector(RouterProxy)
    .get({
      router: new VueRouter(options),
      ...options,
    });
}

export const $$router = Injector(RouterProxy);
