const ARRAY_INFLUENTIAL_METHODS = ['push', 'pop', 'unshift', 'shift', 'slice', 'reverse', 'fill', 'copyWithin', 'sort'];

export function createProxy(obj, config) {
  if (typeof obj !== 'object' || obj === null) return obj;
  return new Proxy(obj, {
    get(target, p) {
      return config.get ? config.get(target, p) : target[p];
    },
    set(target, p, value) {
      config.set && config.set(p, value);
      target[p] = proxyObject(value, config);
      return true;
    },
  });
}

export function proxyObject(obj, config) {
  if (typeof obj !== 'object' || obj === null) return obj;

  if (obj instanceof Array) {
    return proxyArray(obj, createProxy(obj, config));
  }

  if (typeof obj === 'object') {
    Object.keys(obj)
      .forEach((key) => {
        obj[key] = proxyObject(obj[key], config);
      });
    return createProxy(obj, config);
  }

  return obj;
}

export function proxyArray(array, proxy) {
  ARRAY_INFLUENTIAL_METHODS.forEach((key) => {
    array[key] = array[key].bind(proxy);
  });
  return proxy;
}
