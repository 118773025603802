import Vue from 'vue'

import './plugins/element/element';
import './plugins/swiper/vue-swiper';
import './plugins/echarts/echarts';
import './plugins/contextmenu/contextmenu';
import './plugins/mavon-editor/mavon-editor';
import './permission'; // permission control

import UIComponents from '@/libs/common/components';
import EnComponents from '@/components';

import App from './libs/app/app'
import {$routerAdmin} from './pages/routes';
import {$project} from "@/libs/project/project";
import store from './store'

// register global utility filters.
import * as filters from './libs/filters'
// register global utility mixins.
import mixin from './utils/mixin'

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.use(UIComponents)
Vue.use(EnComponents)

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.mixin(mixin);

Vue.config.productionTip = false

const app = new Vue({
  store,
  router: $routerAdmin.ctx,
  render: h => h(App),
});

app.$mount('#app');

$project.setApp(app);

export default app;
