/* *
 * 店铺相关API
 */

import request from '@/utils/request'

/**
 * 获取店铺列表
 * @param params
 * @returns {Promise<any>}
 */
export function getShopList(params) {
  return request({
    url: 'admin/shops/page',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取所有店铺
 */
export function getAllShopList(params) {
  return request({
    url: 'admin/shops/shoplist',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取店铺详情
 * @param shop_id
 */
export function getShopDetail(shop_id, isRefuse) {
  if (isRefuse) {
    return request({
      url: `admin/systems/sysLog/remarks/${shop_id}`,
      method: 'get'
    })
  } else {
    return request({
      url: `admin/shops/${shop_id}`,
      method: 'get'
    })
  }
}

/**
 * 关闭店铺
 * @param shop_id
 */
export function closeShop(shop_id) {
  return request({
    url: `admin/shops/disable/${shop_id}`,
    method: 'put',
    log: {
      type: '101',
      name: '下架商户全部活动'
    }
  })
}

/**
 * 恢复店铺
 * @param shop_id
 */
export function recoverShop(shop_id) {
  return request({
    url: `admin/shops/enable/${shop_id}`,
    method: 'put'
  })
}

/**
 * 修改审核店铺
 * @param shop_id
 * @param params
 */
export function editAuthShop(shop_id, params) {
  return request({
    url: `admin/shops/audit`,
    method: 'put',
    data: params,
    log: {
      type: '1',
      name: '审核商户'
    }
  })
}

/**
 * 获取店铺模板列表
 * @param params
 * @returns {Promise<any>}
 */
export function getShopThemeList(params) {
  return request({
    url: 'admin/shops/themes',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 添加店铺模板
 * @param params
 * @returns {*}
 */
export function addShopTheme(params) {
  return request({
    url: 'admin/shops/themes',
    method: 'post',
    data: params
  })
}

/**
 * 获取店铺模板详情
 * @param id
 * @returns {Promise<any>}
 */
export function getShopThemeDetail(id) {
  return request({
    url: `admin/shops/themes/${id}`,
    method: 'get'
  })
}

/**
 * 修改店铺模板
 * @param id
 * @param params
 * @returns {*}
 */
export function editShopTheme(id, params) {
  return request({
    url: `admin/shops/themes/${id}`,
    method: 'put',
    data: params
  })
}

/**
 * 删除店铺模板
 * @param id
 * @returns {*}
 */
export function deleteShopTheme(id) {
  return request({
    url: `admin/shops/themes/${id}`,
    method: 'delete'
  })
}

/**
 * 分页查询店铺管理员
 * @param params
 */
export function getShopAdmin(params) {
  return request({
    url: '/admin/shops/shopAdmin/page',
    method: 'get',
    params
  })
}
/**
 * 修改账户状态
 * @param params
 */
export function disableShopAdmin(params) {
  return request({
    url: '/admin/shops/shopAdmin/disable',
    method: 'put',
    params,
    log: {
      type: '101',
      name: params.user_state === 0 ? '禁用商户' : '启用商户'
    }
  })
}

/**
 * 获得店铺开户信息
 * @param
 */
export function sellerShopsAccount(params) {
  return request({
    url: '/admin/shops/account',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 退款活动top5
 * @param
 */
export function refundActTop5(params) {
  return request({
    url: '/admin/after-sales/refund/refundtop',
    method: 'get',
    loading: false,
    params
  })
}
