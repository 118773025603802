<template>
  <el-container class="layout-container">
    <el-header>
      <nav-bar></nav-bar>
    </el-header>

    <el-container class="main-container">
      <el-aside :width="ctrl.sidebar.width + 'px'">
        <side-bar></side-bar>
      </el-aside>
      <el-main>
        <router-tab></router-tab>
        <router-outlet class="root-router-outlet"></router-outlet>
      </el-main>
    </el-container>
  </el-container>
</template>

<script lang="js">
import {Component, Vue} from 'vue-property-decorator';
import {$layout} from './layout.service';

import NavBar from './nav-bar/nav-bar.vue';
import SideBar from './side-bar/side-bar.vue';
import RouterOutlet from './router-outlet/router-outlet.vue';
import RouterTab from './router-tab/router-tab.vue';

export default @Component({
  components: {
    NavBar,
    SideBar,
    RouterOutlet,
    RouterTab,
  },
})
class Layout extends Vue {
  ctrl = $layout;
}
</script>

<style lang="scss">
.layout-container {
  background-color: #F4F5F8;
  width: 100vw;
  height: 100vh;

  .el-header {
    z-index: 100;
    background: #fff;
    padding: 0 8px 0 16px;
  }

  .el-aside {
    transition: all 0.3s;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .main-container {
    width: 100vw;
    height: calc(100vh - 60px);
  }

  .root-router-outlet {
    margin-top: 20px;
    overflow: hidden scroll;
  }
}
</style>
