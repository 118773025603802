<template>
  <div class="menu-item">
    <div class="menu-item-radius radius-top" v-if="root && isActive">
      <div class="menu-item-radius-inner"></div>
    </div>

    <div class="menu-item-radius radius-bottom" v-if="root && isActive">
      <div class="menu-item-radius-inner"></div>
    </div>

    <template v-if="!realType">
      <template v-for="(subItem, index) in submenus">
        <menu-item
          :key="index"
          v-if="!getMeta(subItem).isVirtual"
          :type="getType(subItem)"
          :item="subItem"
          :root="true"
          @active="e => {childActiveMap = {...childActiveMap, [index]: e}}"
        />
      </template>
    </template>

    <el-submenu
      ref="elSubmenu"
      v-if="realType === 'submenu'"
      :index="realItem.name"
      :disabled="getMeta(realItem).isDisabled"
      :class="{rounded, 'is-active': isActive}"
    >
      <template v-if="realItem" slot="title">
        <i v-if="getMeta(realItem).icon" :class="getMeta(realItem).icon"></i>
        <span>{{ realItem.name }}</span>
      </template>
      <template v-for="(subItem, index) in realItem.children">
        <menu-item
          :key="index"
          v-if="!getMeta(subItem).isVirtual"
          :type="getType(subItem)"
          :item="subItem"
          @active="e => {childActiveMap = {...childActiveMap, [index]: e}}"
        />
      </template>
    </el-submenu>

    <router-link v-if="realType === 'item'" :to="{name: realItem.name}">
      <el-menu-item
        v-if="realItem && !getMeta(realItem).isVirtual"
        :index="realItem.name"
        :disabled="getMeta(realItem).isDisabled"
        :class="{rounded, 'is-active': isActive}"
      >
        <i v-if="getMeta(realItem).icon" :class="getMeta(realItem).icon"></i>
        <template slot="title">
          <span>{{ realItem.name }}</span>
        </template>
      </el-menu-item>
    </router-link>
  </div>
</template>

<script lang="js">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {$layout} from '@/libs/layout/layout.service';

export default @Component({
  name: 'MenuItem'
})
class MenuItem extends Vue {
  @Prop() type;
  @Prop() item;
  @Prop() submenus;
  @Prop() width;
  @Prop() rounded;
  @Prop() root;
  @Prop({default: '/'}) parentPath;

  realType = '';
  realItem = null;
  isActive = false;

  childActiveMap = {};

  getType(item) {
    return item.children && item.children.length > 0 ? 'submenu' : 'item';
  }

  getMeta(item) {
    return item['meta'] || {};
  }

  mounted() {
    if (this.root) {
      if (this.type === 'submenu' && this.item.children.length === 1) {
        this.realType = 'item';
        [this.realItem] = this.item.children;
      }
    }

    if (!this.realType) {
      this.realType = this.type;
    }

    if (!this.realItem) {
      this.realItem = this.item;
    }

    setTimeout(() => {
      if (this.realItem) {
        this.checkActiveState();
      }
    });
  }

  checkActiveState() {
    this.isActive = [this.$route.name, this.$route.meta.bornIn].includes(this.realItem.name);
    this.$emit('active', this.isActive);
  }

  @Watch('childActiveMap')
  childActiveMapChange(active) {
    this.isActive = Object.values(active).filter(a => a).length > 0;
    this.$emit('active', this.isActive);

    if (this.isActive && this.$refs.elSubmenu && !this.$refs.elSubmenu.opened) {
      this.$refs.elSubmenu.handleClick();
    }
  }

  @Watch('$route')
  onRouteChange(newRoute) {
    if (this.realItem && newRoute) {
      this.checkActiveState();
    }

    if (this.isActive) {
      $layout.sidebar.activeRouteChange.next(newRoute.name);
    }
  }
}
</script>

<style lang="scss">
$--color-primary: teal;
$--color-background: #F4F5F8;

.menu-item {
  position: relative;

  $size: 40px;

  .el-submenu.is-active > .el-submenu__title {
    font-weight: bold;
  }

  .menu-item-radius {
    position: absolute;
    right: 0;
    width: $size / 2;
    height: $size / 2;
    background: $--color-background;
    z-index: 100;
    overflow: hidden;

    &.radius-top {
      top: 0;
      transform: translateY(-100%);

      .menu-item-radius-inner {
        border-bottom-right-radius: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.radius-bottom {
      bottom: 0;
      transform: translateY(100%);

      .menu-item-radius-inner {
        border-top-right-radius: 50%;
        transform: translate(-50%, 0);
      }
    }

    .menu-item-radius-inner {
      position: absolute;
      top: 0;
      left: 0;
      width: $size;
      height: $size;
      background: $--color-primary;
      z-index: 100;
    }
  }

  .el-submenu {
    .el-menu-item {
      height: 42px;
      line-height: 42px;

      span {
        font-size: 90%;
        margin-left: 12px;
      }
    }

    .el-submenu {
      .el-submenu__title {
        height: 42px;
        line-height: 42px;

        span {
          font-size: 90%;
          margin-left: 12px;
        }
      }
    }
  }
}
</style>
