import {Subject} from 'rxjs';

export class LayoutSideBarService {
  activeRouteChange = new Subject();
  width = 200;
  isCollapse = false;
  backgroundColor = '#008080';
  textColor = '#eee';
  activeTextColor = '#fff';
  submenus = [];

  toggleSidebar = () => {
    this.isCollapse = !this.isCollapse;
    this.width = this.isCollapse ? 63 : 200;
  };
}
