import {$$router} from '@/libs/router/router';

export class LayoutRouterTabService {
  tabs = [];

  isRootTab = (tab) => tab.name === '首页';

  isLastTab = (tab) => {
    const lastTab = this.tabs[this.tabs.length - 1];
    return tab.fullPath === lastTab.fullPath;
  };

  closeTab = (tab) => {
    let index = 0;
    let selected = false;
    this.tabs = this.tabs.filter((t, i) => {
      const isNotEq = tab.name !== t.name;
      if (!isNotEq) {
        index = i;
        selected = tab.selected;
      }
      return isNotEq;
    });

    if (!selected) {
      return;
    }

    if (this.tabs.length === 0) {
      $$router.get()
        .forward('/home');
    } else if (this.tabs[index]) {
      $$router.get()
        .forward(this.tabs[index].fullPath);
    } else {
      $$router.get()
        .forward(this.tabs[index - 1].fullPath);
    }
  };

  closeOthers = (tab) => {
    this.tabs = this.tabs.filter(t => {
      return tab.name === t.name || t.name === '首页';
    });

    this.selectTab(tab);
  }

  selectTab = (tab) => {
    this.tabs = this.tabs.map((t) => {
      t.selected = t.fullPath.split('?')[0] === tab.fullPath.split('?')[0];
      if (t.selected) {
        const currentPath = window.location.hash.substring(1);
        if (currentPath.split('?')[0] !== t.fullPath.split('?')[0]) {
          $$router.get().forward(t.fullPath);
        }
      }
      return t;
    });
  };

  appendTab = (view) => {
    const newView = {
      ...view,
    };

    if (newView.meta.queryKeys && newView.meta.queryKeys.length > 0) {
      newView.name = `${newView.name} (${newView.meta.queryKeys.map(key => newView.query[key] || newView.params[key]).join()})`;
    }

    const loaded = this.tabs.some((t) => t.name === newView.name);
    if (!loaded && !newView.meta.isDisableCached) {
      this.tabs.push({
        ...newView,
        selected: true,
      });
    }
    this.selectTab(newView);
  };

  refresh = (route) => {
    if (!route.matched || route.matched.length === 0) {
      return;
    }
    const page = route.matched[route.matched.length - 1].instances.default;
    if (page) {
      page.refresh && page.refresh();
    }
  };
}
