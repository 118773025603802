export class ProjectService {
  type;
  app;

  setType(type) {
    this.type = type;
  }

  setApp(app) {
    this.app = app;
  }

  getApp() {
    return this.app;
  }

  isAdmin() {
    return this.type === 'admin';
  }

  isSeller() {
    return this.type === 'seller';
  }
}

export const $project = new ProjectService();
