import { LayoutSideBarService } from './side-bar/side-bar.service';
import { LayoutRouterTabService } from './router-tab/router-tab.service';
import { LayoutNavBarService } from './nav-bar/nav-bar.service';

/**
 * 布局服务
 */
export class LayoutService {
  sidebar = new LayoutSideBarService();
  routerTab = new LayoutRouterTabService();
  navbar = new LayoutNavBarService()
}

export const $layout = new LayoutService();
