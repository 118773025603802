const api = require('./api');
const domain = require('./domain');

// 后台提供的API模式【dev|pro】
const apiModel = location.href.includes('//manager') ? 'pro' : 'dev';
// 当前前台是否为开发模式
const isDev = apiModel === 'dev';

module.exports = {
  // API模式
  api_model: apiModel,
  // 开发环境下的API
  api_dev: api.dev,
  // 生产环境下的API
  api_pro: api.pro,
  // 开发环境下的域名
  domain_dev: domain.dev,
  // 生产环境下的域名
  domain_pro: domain.pro,
  // API根据前台环境自动适配
  api: isDev ? api.dev : api.pro,
  // 域名根据前台环境自动适配
  domain: isDev ? domain.dev : domain.pro,
};
