import {Foundation} from '@/libs/common/utils';

export function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return `${time + label}s`;
}

export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' 分钟');
  }
  if (between < 86400) {
    return pluralize(~~(between / 3600), ' 小时');
  }
  return pluralize(~~(between / 86400), ' 天');
}

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }

  if (`${time}`.length === 10) {
    time = +time * 1000;
  }

  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    date = new Date(parseInt(time, 10));
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  return format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1];
    if (result.length > 0 && value < 10) {
      value = `0${value}`;
    }
    return value || 0;
  });
}

export function formatTime(time, option) {
  time = +time * 1000;
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  }

  if (diff < 3600) { // less 1 hour
    return `${Math.ceil(diff / 60)}分钟前`;
  }

  if (diff < 3600 * 24) {
    return `${Math.ceil(diff / 3600)}小时前`;
  }

  if (diff < 3600 * 24 * 2) {
    return '1天前';
  }

  if (option) {
    return parseTime(time, option);
  }
  return `${d.getMonth() + 1}月${d.getDate()}日${d.getHours()}时${d.getMinutes()}分`;
}

/* 数字 格式化 */
export function nFormatter(num, digits) {
  const si = [
    {
      value: 1E18,
      symbol: 'E',
    },
    {
      value: 1E15,
      symbol: 'P',
    },
    {
      value: 1E12,
      symbol: 'T',
    },
    {
      value: 1E9,
      symbol: 'G',
    },
    {
      value: 1E6,
      symbol: 'M',
    },
    {
      value: 1E3,
      symbol: 'k',
    },
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits)
        .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }
  return num.toString();
}

export function html2Text(val) {
  const div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

export function toThousandslsFilter(num) {
  return (+num || 0).toString()
    .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}

/**
 * 金钱单位置换  2999 --> 2,999.00
 * @param val
 * @param unit
 * @returns {*}
 */
export function unitPrice(val, unit) {
  return (unit || '') + Foundation.formatPrice(val);
}

/**
 * 处理unix时间戳，转换为可阅读时间格式
 * @param unix
 * @param format
 * @returns {*|string}
 */
export function unixToDate(unix, format) {
  let _format = format || 'yyyy-MM-dd hh:mm:ss';
  const d = new Date(unix * 1000);
  const o = {
    'M+': d.getMonth() + 1,
    'd+': d.getDate(),
    'h+': d.getHours(),
    'm+': d.getMinutes(),
    's+': d.getSeconds(),
    'q+': Math.floor((d.getMonth() + 3) / 3),
    S: d.getMilliseconds(),
  };
  if (/(y+)/.test(_format)) _format = _format.replace(RegExp.$1, (`${d.getFullYear()}`).substr(4 - RegExp.$1.length));
  // eslint-disable-next-line no-restricted-syntax
  for (const k in o) {
    if (new RegExp(`(${k})`).test(_format)) _format = _format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : ((`00${o[k]}`).substr((`${o[k]}`).length)));
  }
  return _format;
}

/**
 * 根据订单状态码返回订单状态
 * @param statusCode
 * @returns {string}
 */
export function unixOrderStatus(statusCode) {
  switch (statusCode) {
    case 'NEW':
      return '新订单';
    case 'INTODB_ERROR':
      return '入库失败';
    case 'CONFIRM':
      return '已确认';
    case 'PAID_OFF':
      return '已付款';
    case 'SHIPPED':
      return '已发货';
    case 'ROG':
      return '已收货';
    case 'COMPLETE':
      return '已完成';
    case 'CANCELLED':
      return '已取消';
    case 'AFTER_SERVICE':
      return '售后中';
    default:
      return '';
  }
}

/**
 * 格式化货品的规格
 * @param sku
 * @returns {*}
 */
export function formatterSkuSpec(sku) {
  if (!sku.spec_list || !sku.spec_list.length) return '';
  return sku.spec_list.map((spec) => spec.spec_value)
    .join(' - ');
}
