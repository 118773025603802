import {getUserRolesPermissions} from '@/api/login'
import Storage from '@/utils/storage'
import {$routerAdmin} from '@/pages/routes';
import {$layout} from '@/libs/layout/layout.service';

const permission = {
  state: {
    routers: $routerAdmin.routes,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routes) => {
      state.addRouters = routes
      state.routers = $routerAdmin.routes.concat(routes);
      $layout.sidebar.submenus = state.routers;
    }
  },
  actions: {
    GenerateRoutes({commit}) {
      const user = Storage.getItem('admin_user')
      if (!user) return Promise.reject()
      let role_id = user.role_id || 0;
      if (user.founder === 1) role_id = 0
      return new Promise((resolve, reject) => {
        if (role_id === 0) {
          commit('SET_ROUTERS', $routerAdmin.dynamicRoutes)
          resolve()
        } else {
          getUserRolesPermissions(role_id).then(response => {
            const dynamicRoutes = filterRoleRouter($routerAdmin.dynamicRoutes, response)
            commit('SET_ROUTERS', dynamicRoutes)
            resolve()
          }).catch(reject)
        }
      })
    }
  }
}

/**
 * 递归筛选出有权限的路由
 * @param routers
 * @param names
 * @returns {Array}
 */
function filterRoleRouter(routers, names) {
  const _routers = []
  routers.forEach(item => {
    if (names.includes(item.name) || (item.meta && item.meta.isVirtual)) {
      if (item.children) {
        item.children = filterRoleRouter(item.children, names)
      }
      _routers.push(item)
    }
  })
  return _routers
}

export default permission
