import { Injector } from '@/libs/injector/injector';
import { proxyObject } from './proxy';

function serializeKey(name, key) {
  return `$${name}.${key.toString()}`;
}

export function createStorage(name, obj) {
  obj.__name__ = name;

  return proxyObject(obj, {
    set(propKey, value) {
      const type = typeof value;
      switch (type) {
        case 'string':
        case 'number':
        case 'boolean':
        case 'bigint':
          break;
        case 'object':
          value = value ? JSON.stringify(value) : value;
          break;
        case 'undefined':
          value = '';
          break;
        default:
          break;
      }

      localStorage.setItem(serializeKey(name, propKey), value);
    },
    get(target, key) {
      let localValue = localStorage.getItem(serializeKey(name, key));

      try {
        if (localValue) {
          // 尝试转换成对象
          localValue = JSON.parse(localValue);
        }
      } catch (e) {
      }

      if (target[key] !== localValue) {
        target[key] = localValue;
      }

      return target[key];
    },
  });
}

export function createClassStorage(name, Type) {
  const obj = Injector(Type)
    .get();
  return createStorage(name, obj);
}

/**
 * 清除所有缓存
 */
export function clearStorage(storage, options) {
  if (!storage.__name__) {
    return;
  }

  Object.keys(storage)
    .forEach((key) => {
      // 如果选项中有 includes 选项，则 includes 中不包含的都跳过
      if (options.includes && !options.includes.includes(key)) {
        return;
      }

      if (options.excludes.includes(key)) {
        return;
      }

      localStorage.removeItem(serializeKey(storage.__name__, key));
    });
}

export function clearClassStorage(Type, options) {
  const obj = Injector(Type)
    .get();
  clearStorage(obj, options);
}
