export function Injector(ClassType) {
  return {
    get(...args) {
      if (!ClassType.instance) {
        ClassType.instance = new ClassType(...args);
      }

      return ClassType.instance;
    },
  };
}
