<template>
  <div class="row align-items-center nav-bar  disable-select">

    <div class="col-auto cursor-pointer" @click="ctrl.navbar.onLogoClick.next()">
      <div class="row no-gutters align-items-center">
        <img class="col-auto logo" :src="ctrl.navbar.logo || '/img/BZOELOGO.png'" alt="logo" />
        <div class="col nav-bar-title pl-2">
          {{ ctrl.navbar.appName }}
        </div>
        <!-- <div class="col-auto pl-2" v-if="isDevEnv">
          <el-tag type="warning">测试服</el-tag>
        </div> -->
      </div>
    </div>

    <div class="col-auto left-actions">
      <div class="row align-items-center no-gutters">
        <template v-for="(action, index) in ctrl.navbar.leftActions">
          <div class="col" :key="index">
            <nav-bar-menu :actions="action.children" :hidden="!action.badge">
              <el-badge is-dot :value="action.badge">
                <div @click="action.handler && action.handler()">
                  <i :class="action.icon"></i>
                  <span>{{ action.title }}</span>
                </div>
              </el-badge>
            </nav-bar-menu>
          </div>
        </template>
      </div>
    </div>

    <div class="col"></div>

    <div class="col-auto user-profile" @click="ctrl.navbar.user.onClick.next()">
      <nav-bar-menu :actions="ctrl.navbar.profileActions">
        <el-avatar size="small" :src="ctrl.navbar.user.avatar || '/img/avatar.png'"></el-avatar>
        <span class="pl-2 text-black-50">{{ ctrl.navbar.user.nickname || '未登录' }}</span>
      </nav-bar-menu>
    </div>

    <template v-if="ctrl.navbar.rightActions && ctrl.navbar.rightActions.length > 0">
      <div class="col-auto p-0 border-right" style="height: 20px"></div>

      <div class="col-auto right-actions">
        <div class="row align-items-center no-gutters">
          <template v-for="(action, index) in ctrl.navbar.rightActions">
            <div class="col pl-2 pr-2" :key="index">
              <nav-bar-menu :actions="action.children">
                <el-badge :max="99" :value="action.badge" :hidden="!action.badge">
                  <div @click="action.handler && action.handler()">
                    <i :class="action.icon"></i>
                    <span>{{ action.title }}</span>
                  </div>
                </el-badge>
              </nav-bar-menu>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="js">
import { Component, Vue } from 'vue-property-decorator';
import { $layout } from '../layout.service';
import NavBarMenu from './nav-bar-menu.vue';
import { api_model } from '@/libs/common/domain'

export default @Component({
  components: { NavBarMenu }
})

class NavBar extends Vue {
  ctrl = $layout;
  isDevEnv = api_model === 'dev';
}

</script>

<style lang="scss">
.nav-bar {
  margin-right: -20px;
  margin-left: -20px;
  height: 60px;
  background-color: #fff;

  .nav-bar-menus {
    .el-menu-item {
      color: #909399 !important;
    }
  }

  .logo {
    padding-right: 8px;
    height: 24px;
  }

  .nav-bar-title {
    font-size: 20px;
  }

  .el-badge__content.is-fixed {
    top: 21px;
    right: 14px;
  }

  .el-badge {
    i {
      transform: translateY(-2px);
      margin-right: 0;
    }
  }
}
</style>
