<template>
  <div class="router-outlet">
    <transition name="fade" mode="out-in">
      <keep-alive :include="keepAlive">
        <router-view></router-view>
      </keep-alive>
    </transition>
  </div>
</template>

<script lang="js">
import {Component, Vue} from 'vue-property-decorator';
import {$layout} from '../layout.service';

export default @Component
class RouterOutlet extends Vue {
  ctrl = $layout;

  get keepAlive() {
    return this.ctrl.routerTab.tabs.reduce((views, route) => {
      route.matched.forEach((pageRef) => {
        const page = pageRef.instances.default;
        if (page && page.$route && !views.includes(page.$route.fullPath)) {
          views.push(page.$route.fullPath);
        }
      });
      return views;
    }, []);
  }
}
</script>

<style lang="scss">
.router-outlet {
  height: calc(100vh - 160px);
}
</style>
